import React, { Component } from 'react';
import { showSnackbar, showSnackbarWithType, showSnackbarWithTimeout } from '../components/Snackbar.js';
import { db } from '../firebase/firebase';

import withAuthorization from '../components/withAuthorization';
import { withUsers } from '../context/UsersContext';
import moment from 'moment';

import Modal from 'react-modal';

import { Table } from 'element-react';
import 'element-theme-default';

class FeedbackUsers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      modalCommentIsOpen: false,
      newComment: '',
      modalDetailedFeedbackIsOpen: false,
      modalDeleteFeedbackIsOpen: false,
      lastSelectedFeedback: '',

      columns: [
        {
          label: "Datum",
          prop: "date",
          width: 180,
          render: (data) => {
              return data.timestamp ? moment(data.timestamp.toDate()).format('HH:mm ddd D MMM YYYY') : "Onbekend";
          }
        },
        {
          label: "Beschrijving",
          prop: "description",
          width: 180,
          render: (data) => {
              return data.description.length > 50 ? data.description.substring(0, 50) + '...' : data.description
          }
        },
        {
          label: "Status",
          prop: "status",
          width: 180,
          sortable: true
        },
        {
          label: "Name",
          prop: "name",
          render: (data) => {
              return this.props.usersContextActions.uidToDisplayName(data.uid);
          }
        },
        {
          label: "Acties",
          fixed: 'right',
          width: 220,
          render: () => {
            return (
              <div>
                <button onClick={this.openCommentModal} className="btn btn-success" type="text" size="small"><i className="material-icons">comment</i></button>
                <button onClick={this.openDetailedFeedbackModal} className="btn btn-primary" type="text" size="small"><i className="material-icons">info</i></button>
                <button onClick={this.openDeleteFeedbackModal} className="btn btn-danger" type="text" size="small"><i className="material-icons" title="Verwijderen" data-toggle="tooltip">delete_forever</i></button>
              </div>
            )
          }
        }
      ],
      data: []
    };
    this.onFeedbacksSnapshotUnsubscriber = null;
  }

  componentDidMount() {
    this.props.usersContextActions.register(this);
    showSnackbarWithTimeout('Gegevens laden..', 1000);

    this.onFeedbacksSnapshotUnsubscriber = db.neighbourhoodCollection('feedbackEntries')
      .where('timestamp', '>', moment().add(-60, 'days').toDate())
      .orderBy('timestamp', 'desc')
      .onSnapshot(this.onFeedbacksSnapshot);

      setTimeout(() => {
          this.setState({ users: this.props.users });
        }, 1000);
  }

  componentWillUnmount() {
    this.props.usersContextActions.unregister(this);
    this.onFeedbacksSnapshotUnsubscriber();
  }

  onFeedbacksSnapshot = querySnapshot => {
    let remarks = [];
    querySnapshot.forEach(doc => {
      const data = doc.data();
      remarks.push({
        key: doc.id,
        ...data
      });
    });

    this.setState({ data: remarks });
  }

  deleteFeedback = () => {
    const feedback = this.state.lastSelectedFeedback;
    db.neighbourhoodCollection('feedbackEntries').doc(feedback.key).delete().then(() => {
      showSnackbar('Opmerking is verwijderd');
    })
      .catch((error) => {
        console.error("Error removing document: ", error);
        showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
      });
  }

  openDetailedFeedbackModal = () => {
    this.setState({ modalDetailedFeedbackIsOpen: true });
  }

  closeDetailedFeedbackModal = () => {
    this.setState({ modalDetailedFeedbackIsOpen: false });
  }

  openDeleteFeedbackModal = () => {
    this.setState({ modalDeleteFeedbackIsOpen: true });
  }

  closeDeleteFeedbackModal = () => {
    this.setState({ modalDeleteFeedbackIsOpen: false });
  }

  openCommentModal = () => {
    this.setState({ modalCommentIsOpen: true });
  }

  closeCommentModal = () => {
    this.setState({ modalCommentIsOpen: false });
  }

  handleDeleteFeedbackSaveClicked = () => {
    this.closeDeleteFeedbackModal();
    this.deleteFeedback();
  }

  setComment = () => {
      if (this.state.lastSelectedFeedback.key != null) {
          db.neighbourhoodCollection('feedbackEntries').doc(this.state.lastSelectedFeedback.key).update({
              status: "Beantwoord",
              comment: {
                  uid: null, // TODO
                  content: this.state.newComment,
                  timestamp: new Date()
              }
          });
      }
      this.closeCommentModal();
  }

  render() {
    return (
      <div className="container opmerkingen  content">

        <Table
          style={{width: '100%'}}
          columns={this.state.columns}
          data={this.state.data}
          stripe={true}
          highlightCurrentRow={true}
          onCurrentChange={lastSelectedFeedback => this.setState({ lastSelectedFeedback })}
        />

        <Modal
            className="Modal__Bootstrap modal-dialog"
            closeTimeoutMS={150}
            isOpen={this.state.modalCommentIsOpen}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Reactie plaatsen</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCommentModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12" id="modal-description">{this.state.lastSelectedFeedback.description}</div>
                        <div className="col-lg-12"><hr /></div>
                        <div className="col-lg-12 col-xs-12" id="modal-comment">
                            <h5>Reactie</h5>
                            <textarea className="form-control" onChange={(event) => {
                                this.setState({ newComment: event.target.value });
                            }}>{this.state.lastSelectedFeedback.comment != null ? this.state.lastSelectedFeedback.comment.content : ''}</textarea>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeCommentModal}>Annuleren</button>
                    <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.setComment}>Beantwoorden</button>
                </div>
            </div>
        </Modal>

        <Modal
          className="Modal__Bootstrap modal-dialog"
          closeTimeoutMS={150}
          isOpen={this.state.modalDetailedFeedbackIsOpen}
          onRequestClose={this.closeDetailedFeedbackModal}
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Opmerking bekijken</h4>
              <button type="button" className="close" onClick={this.closeDetailedFeedbackModal}>
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Annuleren</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Door: {this.props.usersContextActions.uidToDisplayName(this.state.lastSelectedFeedback.uid)}</p><br/>
              <textarea disabled={true} value={this.state.lastSelectedFeedback.description}></textarea>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.closeDetailedFeedbackModal}>Sluiten</button>
            </div>
          </div>
        </Modal>

        <Modal
            className="Modal__Bootstrap modal-dialog"
            closeTimeoutMS={150}
            isOpen={this.state.modalDeleteFeedbackIsOpen}
            onRequestClose={this.closeDeleteFeedbackModal}
            ariaHideApp={false}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Opmerking verwijderen</h4>
                    <button type="button" className="close" onClick={this.closeDeleteFeedbackModal}>
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Annuleren</span>
                    </button>
                </div>
                <div className="modal-body">
                    <span>Weet je zeker dat je de opmerking wilt verwijderen?</span>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.closeDeleteFeedbackModal}>Annuleren</button>
                    <button type="button" className="btn btn-danger btn-outline" onClick={this.handleDeleteFeedbackSaveClicked}>Verwijderen</button>
                </div>
            </div>
        </Modal>

      </div>
    );
  }

}

const authCondition = (authUser) => !!authUser;

export default withUsers(withAuthorization(authCondition)(FeedbackUsers))
