import React from 'react';
export default function NewUserDeleteAction(props) {
    return (
        <button className="btn btn-danger"
            onClick={() => {
                props.functions.openDeleteUserModal(props.user);
            }}>
            <i className="material-icons" title="Verwijderen" data-toggle="tooltip">delete_forever</i>
        </button>
    )
}
