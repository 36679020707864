import React, { Component } from 'react';

class Changelog extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className="container  content">

                <div className="jumbotron jumbotron-fluid">
                    <div className="container">
                        <h2>01-11-2024</h2>
                        <p className="lead"># - Nieuwe rollen beschikbaar (Wijkagent/Gemeente) en wordt daarmee uitgesloten van planning</p>
                        <p className="lead"># - Aanpassen van team naam, kleur en eventueel notitie is nu mogelijk</p>
                    </div>
                </div>

                <div className="jumbotron jumbotron-fluid">
                    <div className="container">
                        <h2>28-10-2024</h2>
                        <p className="lead"># - Tooltips toegevoegd (zweef met je muis over icons etc en er komt hulp tekst te voorschijn)</p>
                    </div>
                </div>

            </div>
        );

    }
}


export default Changelog;
