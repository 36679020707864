import React from 'react';
import { SketchPicker } from 'react-color';

export default function InAppReminderDaysBefore(props) {
    return (
        <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">Team wijzigen</h4>
                <button
                    type="button"
                    className="close"
                    onClick={props.close}>
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">{props.cancelButtonText || 'Annuleren'}</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <label htmlFor="teamName">Teamnaam</label>
                    <input
                        className="form-control"
                        id="editName"
                        name="editName"
                        type="text"
                        aria-describedby="teamNameHelp"
                        value={props.editName}
                        onChange={props.onInputChange}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="teamColor">Teamkleur</label>
                    <SketchPicker color={props.teamColor}
                        id="teamColor" aria-describedby="teamColorHelp" className="colorPicker"
                        onChangeComplete={props.onChangeTeamColor} />
                </div>

                <div className="form-group">
                    <label htmlFor="teamNote">Notitie</label>
                    <small id="teamNoteHelp" className="form-text text-muted">Optioneel</small>
                    <input
                        className="form-control"
                        id="editNote"
                        name="editNote"
                        type="text"
                        aria-describedby="teamNoteHelp"
                        value={props.editNote}
                        onChange={props.onInputChange}
                    />
                </div>

                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={props.close}>
                        {props.cancelButtonText || 'Annuleren'}
                </button>
                <button
                    className="btn btn-outline-primary"
                    title="Aanpassen"
                    data-toggle="tooltip"
                    type="submit"
                    onClick={props.saveEditTeam}
                >
                    Aanpassen
                </button>
            
            </div> 
        </div>
        
    );
}
