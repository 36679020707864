import React from 'react';


export default function UsersTableRow(props) {
  if (props.currentUser === null) {
    return null;
  }

    return (
        <span>
            <button className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Notificaties aanpassen"
                onClick={() => {
                    props.functions.openEditUserNotifiersModal(props.user);
                }}>
                <i className="material-icons">notifications</i>
            </button>
        </span>
    )
}
