import React from 'react';
import moment from 'moment';

export default function RouteRow(props) {
    const { route, history } = props;
    return (
        <tr>
            <td>{moment(route.date.toDate()).format("dd L")}</td>
            <td>{moment(route.start.toDate()).format('LT')}</td>
            <td>{route.end ? moment(route.end.toDate()).format('LT') : 'Nog bezig'}</td>
            <td data-toggle="tooltip" data-placement="top" title="Ronde is gestart door deze gebruiker">{props.getDisplayNameByUid(route.uid)}</td>
            <td>{Object.keys(route.users).sort((a, b) => a === route.uid ? -1 : b - a).map(props.getDisplayNameByUid).join(', ')}</td>

            {route.logEntriesCount > 0 ? (
                <td style={{ textAlign: "center" }}>
                    <button className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Toon logboekregels"
                        onClick={() => {
                            history.push('/logboek', { lastSelectedRoute: route });
                        }}>
                        {route.logEntriesCount}
                    </button>
                </td>
            ) : (<td style={{ textAlign: "center" }}>0</td>)}

            <td style={{ display: "inline-flex" }}>
                <button className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Toon kaart"
                    onClick={() => {
                        props.openMapRouteModal(route);
                    }}>
                    <i className="material-icons" title="Kaart bekijken" data-toggle="tooltip">info</i>
                </button>
                <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Verwijderen"
                    onClick={() => {
                        props.openDeleteRouteModal(route);
                    }}>
                    <i className="material-icons" title="Verwijderen" data-toggle="tooltip">delete_forever</i>
                </button>
            </td>
        </tr>
    );
}
