import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { db, getNeighbourhoodId } from '../firebase/firebase';
import { firebase } from '@firebase/app';
import withAuthorization from '../components/withAuthorization';
import { isFunction, DAYS, capitalize } from '../utils/Utils';
import Color from 'color';
import { showSnackbar, showSnackbarWithType } from '../components/Snackbar';
import Modal from 'react-modal';
import moment from 'moment';
moment.locale('nl');

const groupBy = (arr, key) => {
    return arr.reduce((previousValue, currentValue) => {
        (previousValue[currentValue[key]] = previousValue[currentValue[key]] || []).push(currentValue);
        return previousValue;
    }, {});
};

const groupByFill = (arr, key, len) => {
    const arrGrouped = groupBy(arr, key);
    const arrFilled = Array(len).fill([]);
    Object.entries(arrGrouped).forEach(([index, item]) => arrFilled[index - 1] = item);
    return arrFilled;
}

class AvailabilityV2 extends Component {

    state = {
        users: [],
        teams: [],
        planningEntries: [],
        userAvailabilityDeviation: [],
        userAvailabilityPreferences: [],
        timeSlotsCount: [],
        timeSlots: [],
        month: null,
        year: null,
        modalSelectBuddiesIsOpen: false,
        lastSelectedUser: null,
    };

    constructor(props) {
        super(props);

        this.state.month = props.month;
        this.state.year = props.year;
        this.state.daysInMonth = props.daysInMonth;
    }

    subscriptions = [];

    registerSubsriber = subscriber => this.subscriptions.push(subscriber);

    unregisterAllSubscribers = () => {
        const callFn = fn => fn();
        this.subscriptions.forEach(callFn);
    }

    componentDidMount() {
        const subscribers = [
            db.neighbourhoodCollection('users')
                .onSnapshot(querySnapshot => this.onSnapshot(querySnapshot, 'users'), this.onError('users')),
            db.neighbourhoodCollection('settings')
                .doc('teams')
                .collection('teams')
                .orderBy('name')
                .onSnapshot(querySnapshot => this.onSnapshot(querySnapshot, 'teams'), this.onError('teams')),
            db.neighbourhoodCollection('userAvailabilityDeviation')
                .where('month', '==', this.state.month)
                .where('year', '==', this.state.year)
                .onSnapshot(querySnapshot => this.onSnapshot(querySnapshot, 'userAvailabilityDeviation'), this.onError('userAvailabilityDeviation')),
            db.neighbourhoodCollection('userAvailabilityPreferences')
                .where('month', '==', this.state.month)
                .where('year', '==', this.state.year)
                .onSnapshot(querySnapshot => this.onSnapshot(querySnapshot, 'userAvailabilityPreferences'), this.onError('userAvailabilityPreferences')),
            db.neighbourhoodCollection('planning_concept')
                .where('month', '==', this.state.month)
                .where('year', '==', this.state.year)
                .onSnapshot(querySnapshot => this.onSnapshot(querySnapshot, 'planningEntries'), this.onError('planningEntries')),
        ];
        subscribers.forEach(this.registerSubsriber);
        this.loadTimeSlots();
    }

    componentWillUnmount() {
        this.unregisterAllSubscribers();
    }

    loadTimeSlots = async _ => {
        const timeSlotsDoc = await db.neighbourhoodCollection('settings').doc('timeSlots').get();
        let timeSlotsCount = 3;
        let timeSlots = ['Middag', 'Vroege avond', 'Late avond'];

        if (timeSlotsDoc.exists) {
            const timeSlotsData = timeSlotsDoc.data();
            timeSlotsCount = timeSlotsData.timeSlotsCount;
            timeSlots = [];
            for (let i = 1; i <= timeSlotsCount; i++) {
                timeSlots.push(timeSlotsData[`timeSlot${i}`])
            }
        }

        this.setState({
            timeSlotsCount,
            timeSlots,
        });
    }

    onSnapshot = (querySnapshot, name, subscribers = []) => {
        let snapshotData = this.mapQuerySnapshot(querySnapshot);

        // filter out users that have the role of wijkagent/gemeente
        if (name === 'users') {
            snapshotData = snapshotData.filter(user => user.roles && user.roles.indexOf('wijkagent') === -1 && user.roles.indexOf('gemeente') === -1);
        }

        this.setState({ [name]: snapshotData });

        // Call potential subscribers
        const callSubscriber = subscriber => isFunction(subscriber) ? subscriber(snapshotData) : null;
        subscribers.forEach(callSubscriber);
    }

    onError = tag => error => console.error(`[Availability.v2.js] (${tag})`, error);

    mapQuerySnapshot = querySnapshot =>
        querySnapshot.docs
            .map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

    onUserPlanClick = (availability, shouldAdd) => {
        const currentPlanningEntries = this.state.planningEntries;
        const firstTeam = this.state.teams[0]
        let newPlanningEntries = [];

        if (shouldAdd) {
            newPlanningEntries = currentPlanningEntries.concat([{
                ...availability,
                group: firstTeam ? firstTeam.name : ""
            }]);
        } else {
            newPlanningEntries = currentPlanningEntries.filter(planningEntry => planningEntry.key !== availability.key);
        }

        this.setState({ planningEntries: newPlanningEntries })
        this.shouldBlockNavigation = true;
    }

    onUserGroupChange = (availability, groupName) => {
        const currentPlanningEntries = this.state.planningEntries;

        currentPlanningEntries.find(entry =>
            entry.dayOfMonth === availability.dayOfMonth
            && entry.timeOfDay === availability.timeOfDay
            && entry.uid === availability.uid
        ).group = groupName;

        this.setState({ planningEntries: currentPlanningEntries })
        this.shouldBlockNavigation = true;
    }

    toggleIsPlanning = () => this.setState(prevState => ({ isPlanning: !prevState.isPlanning }));

    handleChange = event => this.setState({ [event.target.name]: event.target.value });

    savePlanningConcept = async () => {
        const { planningEntries, month } = this.state;

        this.setState({ isSaving: true });
        showSnackbar('Concept opslaan');

        const neighbourhoodId = getNeighbourhoodId();
        const updatePlanningConcept = firebase.functions().httpsCallable('updatePlanningConcept');

        let saved = false;
        try {
            await updatePlanningConcept({
                newPlanning: planningEntries,
                neighbourhoodId,
                month
            });

            showSnackbarWithType('Concept is opgeslagen', 'success', 5000);
            saved = true;
        } catch (error) {
            console.error(error);
            showSnackbarWithType('Er ging iets fout bij het opslaan van het concept', 'planning', 5000);
        }

        this.setState({ isPlanning: false, isSaving: false });
        return saved;
    }

    savePlanning = async () => {
        const savedConcept = await this.savePlanningConcept();
        if (!savedConcept) return;

        const { planningEntries, month } = this.state;

        this.setState({ isSaving: true });
        showSnackbar('Planning opslaan');

        const neighbourhoodId = getNeighbourhoodId();
        const updatePlanning = firebase.functions().httpsCallable('updatePlanning');

        try {
            await updatePlanning({
                newPlanning: planningEntries,
                neighbourhoodId,
                month
            });

            showSnackbarWithType('Planning is opgeslagen', 'success', 5000);
        } catch (error) {
            console.error(error);
            showSnackbarWithType('Er ging iets fout bij het opslaan van de planning', 'planning', 5000);
        }

        this.setState({ isPlanning: false, isSaving: false });
    }

    closeBuddiesUserModal = () => {
        this.setState({ modalSelectBuddiesIsOpen: false });
    }

    handleBuddiesSaveClicked = () => {
        var user = this.state.lastSelectedUser;

        delete user.availableDays;
        delete user.bike;
        delete user.foot;
        delete user.plannedDays;

        db.neighbourhoodCollection('users').doc(user.uid).update(user);
        this.closeBuddiesUserModal();
    }

    openBuddiesModal = (user) => {
        this.setState({ modalSelectBuddiesIsOpen: true, lastSelectedUser: user });
    }

    handleBuddyChecked = (e, user) => {
        const addBuddyCheck = e.target.checked;
        var lastSelectedUser = this.state.lastSelectedUser;
        var buddies = lastSelectedUser.buddies ? lastSelectedUser.buddies : [];

        if (addBuddyCheck) {
            buddies.push(user.uid);
        } else {
            buddies = buddies.filter(function (uid) {
                return uid !== user.uid;
            })
        }

        lastSelectedUser.buddies = buddies;

        this.setState({ lastSelectedUser });
    }

    render() {
        const {
            users,
            year,
            month,
            timeSlots,
            daysInMonth,
            planningEntries,
            userAvailabilityDeviation,
            userAvailabilityPreferences,
            isPlanning,
            isSaving,
            teams,
            modalSelectBuddiesIsOpen,
            lastSelectedUser,
        } = this.state;

        // Filter userAvailabilityDeviation for only active timeslots
        const filteredUserAvailabilityDeviation = userAvailabilityDeviation.filter(item => item.timeOfDay <= timeSlots.length);

        // User available calculations
        const planningEntriesGroupedByUid = groupBy(planningEntries, 'uid');
        const userAvailabilityPreferencesNonDefault = userAvailabilityPreferences.filter(({ id }) => !id.endsWith('_default'))
        const userAvailabilityPreferencesDefault = userAvailabilityPreferences.filter(({ id }) => id.endsWith('_default'))
        const userAvailabilityPreferencesNonDefaultGroupedByUid = groupBy(userAvailabilityPreferencesNonDefault, 'uid');
        const userAvailabilityPreferencesDefaultGroupedByUid = groupBy(userAvailabilityPreferencesDefault, 'uid');
        users.forEach(user => {
            const preference = userAvailabilityPreferencesNonDefaultGroupedByUid[user.uid] ? userAvailabilityPreferencesNonDefaultGroupedByUid[user.uid][0] : null;
            const preferenceDefault = userAvailabilityPreferencesDefaultGroupedByUid[user.uid] ? userAvailabilityPreferencesDefaultGroupedByUid[user.uid][0] : null;

            user.foot = (preference && preference.foot) || (preferenceDefault && preferenceDefault.foot);
            user.bike = (preference && preference.bike) || (preferenceDefault && preferenceDefault.bike);
            user.availableDays = (preference && preference.availableDays) || (preferenceDefault && preferenceDefault.availableDays) || 0;
            user.plannedDays = planningEntriesGroupedByUid[user.uid] ? planningEntriesGroupedByUid[user.uid].length : 0;
            user.planningLastManuallySavedDate = preference && preference.lastManuallySavedDate;
            user.planningLastPersistedDate = preference && preference.lastPersistedDate;
        });

        return (
            <div className="container-fluid content" >
                <div className="row">
                    <div className="col col-lg-3 col-md-12">
                        <div>
                            <TogglePlanningButton
                                isPlanning={isPlanning}
                                toggleIsPlanning={this.toggleIsPlanning}
                            />

                            {isPlanning && (
                                <>
                                    <SavePlanningConceptButton
                                        isSaving={isSaving}
                                        savePlanningConcept={this.savePlanningConcept}
                                    />
                                    <SavePlanningButton
                                        isSaving={isSaving}
                                        savePlanning={this.savePlanning}
                                    />
                                </>
                            )}
                        </div>
                        <UserList
                            users={users}
                            daysInMonth={daysInMonth}
                            userAvailabilityDeviation={filteredUserAvailabilityDeviation}
                            openBuddiesModal={this.openBuddiesModal}
                        />
                    </div>
                    <div className="col col-lg-9 col-md-12">
                        <Planning
                            users={users}
                            year={year}
                            month={month}
                            timeSlots={timeSlots}
                            daysInMonth={daysInMonth}
                            planningEntries={planningEntries}
                            userAvailabilityDeviation={filteredUserAvailabilityDeviation}
                            isPlanning={isPlanning}
                            onUserPlanClick={this.onUserPlanClick}
                            onUserGroupChange={this.onUserGroupChange}
                            teams={teams}
                        />
                    </div>
                </div>

                <Modal
                    className="Modal__Bootstrap modal-dialog"
                    closeTimeoutMS={150}
                    isOpen={modalSelectBuddiesIsOpen}
                    onRequestClose={this.closeBuddiesUserModal}
                    ariaHideApp={false}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Voorkeur(en) wijzigen van {lastSelectedUser && lastSelectedUser.displayName}</h4>
                            <button type="button" className="close" onClick={this.closeBuddiesUserModal}>
                                <span aria-hidden="true">&times;</span>
                                <span className="sr-only">Annuleren</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {users.map((user, index) => { return (<BuddyCheckBox key={index} user={user} lastSelectedUser={lastSelectedUser} handleBuddyChecked={this.handleBuddyChecked} />) })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeBuddiesUserModal}>Annuleren</button>
                            <button type="button" className="btn btn-danger btn-outline" onClick={this.handleBuddiesSaveClicked}>Opslaan</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const BuddyCheckBox = ({
    user,
    lastSelectedUser,
    handleBuddyChecked
}) => {

    if (lastSelectedUser.uid === user.uid) {
        return <div />;
    }

    var isABuddy = false;
    var buddies = lastSelectedUser.buddies;
    if (buddies) {
        isABuddy = buddies.includes(user.uid);
    }

    return (
        <div className="form-check">
            <input className="form-check-input" type="checkbox" value={user} id={user.displayName + "_flexCheckChecked"} checked={isABuddy} onChange={(e) => handleBuddyChecked(e, user)} style={{ marginTop: 5 }} />
            <label className="form-check-label" htmlFor={user.displayName + "_flexCheckChecked"} style={{ marginLeft: 20 }}>
                {user.displayName}
            </label>
        </div>
    );
}

const TogglePlanningButton = ({
    isPlanning,
    toggleIsPlanning
}) => {
    if (isPlanning) return null;
    return (
        <button className="btn btn-primary" onClick={toggleIsPlanning}>Plan maken</button>
    );
}

const SavePlanningConceptButton = ({
    isSaving,
    savePlanningConcept,
}) => {
    return (
        <button
            className="btn btn-primary btn-raised"
            onClick={savePlanningConcept}
            disabled={isSaving}
        >
            Concept Opslaan
        </button>
    );
}

const SavePlanningButton = ({
    isSaving,
    savePlanning,
}) => {
    return (
        <button
            className="btn btn-primary btn-raised"
            onClick={savePlanning}
            disabled={isSaving}
        >
            Planning Opslaan
        </button>
    );
}

const Planning = ({
    users,
    year,
    month,
    timeSlots,
    daysInMonth,
    planningEntries,
    userAvailabilityDeviation,
    isPlanning,
    onUserPlanClick,
    onUserGroupChange,
    teams,
}) => {
    return (
        <div className="sticky wewatch-scrollbar" style={{ paddingTop: '5em' }}>
            <PlanningBars
                timeSlots={timeSlots}
                userAvailabilityDeviation={userAvailabilityDeviation}
                daysInMonth={daysInMonth}
            />

            <PlanningTable
                users={users}
                year={year}
                month={month}
                timeSlots={timeSlots}
                daysInMonth={daysInMonth}
                planningEntries={planningEntries}
                userAvailabilityDeviation={userAvailabilityDeviation}
                isPlanning={isPlanning}
                onUserPlanClick={onUserPlanClick}
                onUserGroupChange={onUserGroupChange}
                teams={teams}
            />
        </div>
    );
}

const PlanningTable = ({
    users,
    year,
    month,
    timeSlots,
    daysInMonth,
    planningEntries,
    userAvailabilityDeviation,
    isPlanning,
    onUserPlanClick,
    onUserGroupChange,
    teams,
}) => {
    const availabilityPerDay = groupByFill(userAvailabilityDeviation, 'dayOfMonth', daysInMonth);
    const planningEntriesPerDay = groupByFill(planningEntries, 'dayOfMonth', daysInMonth);

    return (
        <div style={{ display: 'flex', overflowX: 'scroll', marginTop: '3px' }}>
            {availabilityPerDay.map((availabilityForDay, index) =>
                <PlanningDay
                    key={index}
                    year={year}
                    month={month}
                    users={users}
                    dayOfMonth={index + 1}
                    timeSlots={timeSlots}
                    availabilityForDay={availabilityForDay}
                    planningEntriesForDay={planningEntriesPerDay[index]}
                    isPlanning={isPlanning}
                    onUserPlanClick={onUserPlanClick}
                    onUserGroupChange={onUserGroupChange}
                    teams={teams}
                />
            )}
        </div>
    );
}

class PlanningDay extends Component {
    render() {
        const {
            users,
            year,
            month,
            timeSlots,
            dayOfMonth,
            planningEntriesForDay,
            availabilityForDay,
            isPlanning,
            onUserPlanClick,
            onUserGroupChange,
            teams,
        } = this.props;

        const availabilityGroupedByUid = Object.entries(groupBy(availabilityForDay, 'uid'));
        const planningEntriesGroupedByUid = Object.entries(groupBy(planningEntriesForDay, 'uid'));
        const date = new Date(year, month - 1, dayOfMonth);

        const usersGroupedByUid = groupBy(users, 'uid');
        const availabilityGroupedByUidFiltered = availabilityGroupedByUid.filter(availability => availability[0] in usersGroupedByUid);

        // Check if day is planned
        const planningEntriesForDayGroupedByTeam = groupBy(planningEntriesForDay, 'group');
        const plannedFully = Object.entries(planningEntriesForDayGroupedByTeam).some(([team, arr]) => arr.length >= 2);

        return (
            <div className="card mx-1" style={{ minWidth: 180 }}>
                <div className="card-header d-flex justify-content-between" style={{ fontWeight: '500' }}>
                    <span>{capitalize(DAYS[date.getDay()].abbreviation)} {date.getDate()}-{date.getMonth() + 1}-{date.getFullYear()}</span>
                    {plannedFully ?
                        (
                            <i className="material-icons text-success" title="Voldoende ingepland" data-toggle="tooltip">check_circle</i>
                        ) : (
                            <i className="material-icons text-info" title="Onvoldoende ingepland" data-toggle="tooltip">info</i>
                        )}
                </div>
                <div className="list-group list-group-flush p-0">
                    {availabilityGroupedByUidFiltered.length ? (
                        <>{availabilityGroupedByUidFiltered.map(([uid, availability]) =>
                            <PlanningUser
                                key={uid}
                                user={users.find(user => user.uid === uid)}
                                timeSlots={timeSlots}
                                availability={availability}
                                planningEntries={planningEntriesGroupedByUid.filter(([planningEntriesUid, planningEntries]) => planningEntriesUid === uid)}
                                isPlanning={isPlanning}
                                onUserPlanClick={onUserPlanClick}
                                onUserGroupChange={onUserGroupChange}
                                teams={teams}
                            />
                        )}</>
                    ) : <PlanningNoUser />}
                </div>
            </div>
        );
    }
}

const PlanningUser = ({
    user,
    timeSlots,
    planningEntries,
    availability,
    isPlanning,
    onUserPlanClick,
    onUserGroupChange,
    teams,
}) => {
    let availabilityBadgeClassname = user.plannedDays < user.availableDays
        ? "badge-secondary"
        : user.plannedDays > user.availableDays
            ? "badge-warning"
            : "badge-primary";

    // Extract the planningEntries from the grouped array
    planningEntries = planningEntries.length && planningEntries[0].length ? planningEntries[0][1] : [];

    return (
        <div key={availability.id} className="list-group-item py-1 border-bottom d-flex flex-column justify-content-center" style={{ minHeight: 75 }}>
            <div>
                {user.displayName}
            </div>
            <div>
                <div>
                    {user.foot && (<i className="material-icons small m-0 p-0 ml-2" title="Te voet" data-toggle="tooltip">directions_walk</i>)}
                    {user.bike && (<i className="material-icons small m-0 p-0 ml-2" title="Met fiets" data-toggle="tooltip">directions_bike</i>)}
                    <span className={["badge badge-pill mr-0", availabilityBadgeClassname].join(' ')} title="Aantal: ingepland / beschikbaar" data-toggle="tooltip">{user.plannedDays}/{user.availableDays}</span>
                </div>
                <div className="d-flex">
                    {timeSlots.map((timeSlot, index) =>
                        <PlanningTimeSlot
                            key={index}
                            user={user}
                            timeSlot={timeSlot}
                            planningEntry={planningEntries.find(p => p.timeOfDay === index + 1)}
                            availability={availability.find(a => a.timeOfDay === index + 1)}
                            isPlanning={isPlanning}
                            onUserPlanClick={onUserPlanClick}
                            onUserGroupChange={onUserGroupChange}
                            teams={teams}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const PlanningTimeSlot = ({
    timeSlot,
    user,
    planningEntry,
    availability,
    isPlanning,
    onUserPlanClick,
    onUserGroupChange,
    teams,
}) => {
    const available = !!availability;
    const planned = !!planningEntry;
    const team = planningEntry && (teams.find(team => team.id === planningEntry.group) || teams.find(team => team.name === planningEntry.group));

    const selectedGroup = planningEntry && (teams.find(team => team.id === planningEntry.group) || teams.find(team => team.name === planningEntry.group));
    const selectedGroupColor = selectedGroup ? selectedGroup.color : '';

    return (
        <div>
            {isPlanning ?
                planned ? (
                    <div className="btn-group btn-group-sm m-0 userGroupButtons">
                        <button style={{ backgroundColor: selectedGroupColor, color: "white" }} className={"btn btn-sm dropdown-toggle m-0 btn-raised"} type="button" id="teamMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {team.name ? team.name : "?"}
                        </button>
                        <div className="dropdown-menu dropdown-menu-float" aria-labelledby="teamMenu">
                            {teams.map(team =>
                                <button key={team.id} className="dropdown-item" style={{ backgroundColor: team.color, color: "white" }} onClick={() => onUserGroupChange(availability, team.name)} title={"Notitie:" + team.note || ""} data-toggle="tooltip">{team.name}</button>
                            )}
                            <button className="dropdown-item" style={{ backgroundColor: "white", color: "red" }} onClick={() => onUserPlanClick(availability, false)}>
                                <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                                    <path fill="#a94442" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                ) : (
                    <button type="button" className="btn bmd-btn-icon" disabled={!available} onClick={() => onUserPlanClick(availability, true)}>
                        <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                            <path fill={available ? 'var(--primary)' : 'lightgray'} d="M19,19V7H5V19H19M16,1H18V3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1M11,9H13V12H16V14H13V17H11V14H8V12H11V9Z" />
                        </svg>
                    </button>
                )
                : planned ? (
                    <button style={{ backgroundColor: selectedGroupColor, color: "white" }} className={"btn btn-sm m-0 btn-raised"} type="button" id="teamMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled>
                        {team.name || "?"}
                    </button>
                ) : (
                    <span>{available}</span>
                )}
        </div>
    );
}

const PlanningNoUser = () => {
    return (
        <div className="list-group-item py-3 justify-content-center" style={{ minHeight: 75 }}>
            <span className="text-danger">Niemand beschikbaar</span>
        </div>
    );
}

const PlanningBars = ({
    timeSlots,
    daysInMonth,
    userAvailabilityDeviation,
}) => {
    // Get the availability for each day of the month
    const availabilityGroupedByTimeOfDay = groupBy(userAvailabilityDeviation, 'timeOfDay');
    const availabilityPerTimeSlot = Array(timeSlots.length).fill([]);
    Object.entries(availabilityGroupedByTimeOfDay)
        .forEach(([timeSlot, deviation]) => availabilityPerTimeSlot[timeSlot - 1]
            // Here we use `+timeslot` to cast (string)"1" to (int)1
            = deviation.filter(d => d.timeOfDay === +timeSlot));
    return (
        <>
            <span>Visuele indicatie van bezetting (iedere rij is een ingestelde dagdeel)</span>
            {availabilityPerTimeSlot.map((availability, i) =>
                <PlanningBar
                    key={i}
                    daysInMonth={daysInMonth}
                    userAvailabilityDeviation={availability}
                />)}
            {/* <div style={{ display: 'flex' }}>
                {Array(5).fill().map((x, i) => <div key={i} style={{ flex: 1 }}>Week {i + 1}</div>)}
            </div> */}
        </>
    );
}

const PlanningBar = ({
    daysInMonth,
    userAvailabilityDeviation,
}) => {
    const availabilityGroupedByDay = groupBy(userAvailabilityDeviation, 'dayOfMonth');
    const availabilityPerDay = Array(daysInMonth).fill(0);
    Object.entries(availabilityGroupedByDay).forEach(([dayOfMonth, deviation]) => availabilityPerDay[dayOfMonth - 1] += deviation.length);

    return (
        <>
            <div style={{ display: 'flex', height: 10, border: '1px solid black' }}>
                {availabilityPerDay.map((a, i) => {
                    let backgroundColor = '#f44336';

                    if (a === 2) {
                        backgroundColor = Color.rgb(255, 235, 59);
                    }

                    if (a > 2) {
                        backgroundColor = Color.rgb(76, 175, 80);

                        // Darken the color based on the amount of availability that day
                        const darkenBy = (a) / 10;
                        backgroundColor = backgroundColor.darken(darkenBy);
                    }

                    return <span key={i} style={{ backgroundColor, flex: 1 }}></span>
                })}
            </div>
        </>
    );
}

const UserList = ({
    users,
    daysInMonth,
    userAvailabilityDeviation,
    openBuddiesModal,
}) => {
    return (
        <div>
            {users.map(user => <UserListItem
                key={user.id}
                user={user}
                users={users}
                userAvailabilityDeviation={userAvailabilityDeviation.filter(({ uid }) => uid === user.uid)}
                daysInMonth={daysInMonth}
                openBuddiesModal={openBuddiesModal}
            />)}
        </div>
    );
}

const UserListItem = ({
    user,
    users,
    daysInMonth,
    userAvailabilityDeviation,
    openBuddiesModal,
}) => {
    let availabilityBadgeClassname = user.plannedDays < user.availableDays
        ? "badge-secondary"
        : user.plannedDays > user.availableDays
            ? "badge-warning"
            : "badge-primary";


    var buddies = [];
    var buddiesIndex = 0;
    if (user.buddies) {
        buddies = user.buddies.map(
            function (uid) {
                var user = users.find(u => u.uid === uid);
                return user ? user.displayName : 'Onbekend';
            },
            users
        );
    }

    return (
        <div className="card my-3" style={{ padding: 4 }} key={user.id}>
            <UserAvailabilityBar
                userAvailabilityDeviation={userAvailabilityDeviation}
                daysInMonth={daysInMonth}
            />
            <div className="d-flex justify-content-between">
                <span style={{ fontWeight: '500' }}>{user.displayName}</span>
                <div>
                    {user.planningLastManuallySavedDate && (<i className="material-icons small m-0 p-0 ml-2 cursor-pointer" title={"Handmatig opgeslagen op: " + moment(user.planningLastManuallySavedDate.toDate()).format('LLLL')}>cached</i>)}
                    {user.planningLastPersistedDate && (<i className="material-icons small m-0 p-0 ml-2 cursor-pointer" title={"Automatisch opgeslagen op: " + moment(user.planningLastPersistedDate.toDate()).format('LLLL')}>auto_mode</i>)}
                    {user.foot && <i className="material-icons small m-0 p-0 ml-2" title="Te voet" data-toggle="tooltip">directions_walk</i>}
                    {user.bike && <i className="material-icons small m-0 p-0 ml-2" title="Met fiets" data-toggle="tooltip">directions_bike</i>}
                    <span className={["badge badge-pill mr-0 ml-2", availabilityBadgeClassname].join(' ')} title="Aantal: ingepland / beschikbaar" data-toggle="tooltip">{user.plannedDays}/{user.availableDays}</span>
                </div>
            </div>
            <hr style={{ marginTop: 6, marginBottom: 6 }} />
            <div className="d-flex justify-content-between">
                <span><i className="material-icons small m-0 p-0 ml-2" title="Vaste medelopers" data-toggle="tooltip">group</i> {(buddies.length >= 1 ? buddies.map((buddy) => { buddiesIndex++; return <Buddy buddy={buddy} buddiesIndex={buddiesIndex} buddiesLength={buddies.length} /> }) : <span>Geen voorkeur voor vaste medeloper(s)</span>)}</span>
                <button className="btn btn-primary btn-raised" style={{ fontSize: 10, marginRight: 4 }} onClick={() => { openBuddiesModal(user) }}>Wijzigen</button>
            </div>
        </div >
    );
}

const Buddy = ({
    buddy,
    buddiesIndex,
    buddiesLength
}) => {
    if (buddiesIndex === buddiesLength) {
        return (<span>{buddy}</span>);
    } else {
        return (<span>{buddy + ", "}</span>);
    };
}

const UserAvailabilityBar = ({
    daysInMonth,
    userAvailabilityDeviation,
}) => {
    // Get the availability for each day of the month
    const availabilityPerDay = Array(daysInMonth).fill(0);
    userAvailabilityDeviation.forEach(uad => availabilityPerDay[uad.dayOfMonth - 1]++);

    return (
        <div style={{ display: 'flex', height: 10, border: '1px solid black' }} title="Indicatie van bezetting ten opzichte van de maand" data-toggle="tooltip">
            {availabilityPerDay.map((a, i) => {
                let backgroundColor = 'lightgray';

                if (a > 0) {
                    backgroundColor = Color.rgb(76, 175, 80);

                    // Darken the color based on the amount of availability that day
                    const darkenBy = (a) / 10;
                    backgroundColor = backgroundColor.darken(darkenBy);
                }

                return <span key={i} style={{ backgroundColor, flex: 1 }}></span>
            })}
        </div>
    );
}

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(withRouter(AvailabilityV2));
