import React from 'react';

export default function UsersTableRow(props) {
    if (props.currentUser === null) {
      return null;
    }

    return (
        <span>
            <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Verwijderen" disabled={props.user.key === props.currentUser.uid}
                onClick={() => {
                    props.functions.openDeleteUserModal(props.user);
                }}>
                <i className="material-icons" title="Verwijderen" data-toggle="tooltip">delete_forever</i>
            </button>
        </span>
    )
}
