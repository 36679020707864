import React from 'react';
import UserChangePincodeAction from './UsersChangePincodeAction';
import UserChangePhoneNumberAction from './UsersChangePhoneNumberAction';
import UserChangeRolesAction from './UsersChangeRolesAction';
import UserDeleteAction from './UserDeleteAction';
import UserChangeNotifiersAction from './UsersChangeNotifiersAction';

export default function UserTableRowActions(props) {
    return (
        <td>
            <UserChangeNotifiersAction
                user={props.user}
                currentUser={props.currentUser}
                functions={props.functions}
            />
            <UserChangePincodeAction
                user={props.user}
                functions={props.functions}
            />
            <UserChangePhoneNumberAction
                user={props.user}
                functions={props.functions}
            />
            <UserChangeRolesAction
                user={props.user}
                currentUser={props.currentUser}
                functions={props.functions}
            />
            <UserDeleteAction
                user={props.user}
                currentUser={props.currentUser}
                functions={props.functions}
            />
        </td>
    )
}
