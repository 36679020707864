import React from 'react';
export default function NewUserAddAction(props) {
    return (
        <button className="btn btn-success"
            onClick={() => {
                props.functions.openAddUserModal(props.user);
            }}>
            <i className="material-icons" title="Persoon toelaten" data-toggle="tooltip">person_add</i>
        </button>
    )
}
