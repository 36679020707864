import React, { Component } from 'react';
import moment from 'moment';

import withAuthorization from '../components/withAuthorization';
import { withUsers } from '../context/UsersContext';
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import { db } from '../firebase/firebase';

import Modal from 'react-modal';
import { showSnackbar, showSnackbarWithType, showSnackbarWithTimeout } from '../components/Snackbar.js';
import { Table } from 'element-react';
import 'element-theme-default';

class Logboek extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastSelectedRoute: null,
            logEntries: [],
            users: [],
            infoModalOpen: false,
            commentModalOpen: false,
            modalUpdateLogStatusIsOpen: false,
            modalDeleteLogIsOpen: false,
            selectedLog: {
                key: null,
                timestamp: null,
                location: {
                    latitude: null,
                    longitude: null
                },
                comment: {
                    uid: null,
                    content: null,
                    timestamp: null,
                    from: 'Wijkcoordinator',
                },
                description: null,
            },
            newComment: '',
            lastSelectedLog: null,
            newStatus: null,
            newCommentFrom: 'Wijkcoordinator',

            columns: [
              {
                label: "Beschrijving",
                prop: "description",
                width: 230,
                render: (data) => {
                    return data.description.length > 50 ? data.description.substring(0, 50) + '...' : data.description
                }
              },
              {
                label: "Status",
                prop: "status",
                width: 115,
                sortable: true,
              },
              {
                label: "Datum",
                prop: "timestamp",
                width: 160,
                render: (data) => {
                    return moment(data.timestamp.toDate()).format("D MMMM 'YY");
                },
                sortable: true,
              },
              {
                label: "Tijdstip",
                prop: "timestamp",
                width: 110,
                render: (data) => {
                    return moment(data.timestamp.toDate()).format('HH:mm');
                },
                sortable: true,
              },
              {
                label: "Acties",
                fixed: 'right',
                width: 210,
                render: (data) => {
                  return (
                    <div>
						        {data.status !== 'verwerkt' ? (
							         <button className="btn btn-success" title="Verwerken" data-toggle="tooltip"
								        onClick={() => {
									           this.openUpdateLogStatusModal(data, 'verwerkt');
									           // if (window.confirm('Markeren als verwerkt?')) {
									           //     this.updateStatus(log, 'verwerkt');
									           // }
								          }}><i className="material-icons">done</i>
							           </button>
						        ) : (
							         <button className="btn btn-warning" title="Heropenen" data-toggle="tooltip"
								        onClick={() => {
									           this.openUpdateLogStatusModal(data, 'nieuw');
									           // if (window.confirm('Heropenen? (wordt gemarkeerd als nieuw)')) {
									           //     this.updateStatus(log, "nieuw");
									           // }
								          }}><i className="material-icons">undo</i>
							           </button>
						        )}

						          {this.renderCommentButton(data)}
						          <button type="button" className="btn btn-primary" title="Details bekijken"
							         onClick={() => {
								          this.showModal(data, 'info');
							         }} data-toggle="modal" data-target="#logModal">
							         <i className="material-icons">info</i>
						          </button>

                      {data.isArchived ? (
                        <button className="btn btn-success" title="Herstellen - opnieuw leesbaar in de app" data-toggle="tooltip"
                         onClick={() => {
                              this.unarchiveLog(data);
                           }}><i className="material-icons">unarchive</i>
                          </button>
  						        ) : (
                           <button className="btn btn-warning" title="Archiveren - niet leesbaar meer in de app" data-toggle="tooltip"
                            onClick={() => {
                                 this.archiveLog(data);
                              }}><i className="material-icons">archive</i>
                             </button>
  						        )}

						          <button className="btn btn-danger" title="Verwijderen" data-toggle="tooltip"
							         onClick={() => {
								          this.openDeleteLogModal(data);
							         }}>
							         <i className="material-icons" title="Verwijderen" data-toggle="tooltip">delete_forever</i>
						          </button>
                    </div>
                  )
                }
              }
            ],
        };
        this.onLogEntriesSnapshotUnsubscriber = null;
    }

    onLogEntriesSnapshot = querySnapshot => {
      let remarks = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();
        remarks.push({
          key: doc.id,
          ...data
        });
      });

      this.setState({ logEntries: remarks });
    }

    componentDidMount() {
      this.props.usersContextActions.register(this);
      showSnackbarWithTimeout('Gegevens laden..', 1000);


      var lastSelectedRoute = null;
      if (this.props.location.state) {
        lastSelectedRoute = this.props.location.state.lastSelectedRoute;
      }

      if (!lastSelectedRoute) {
        this.getAllLogEntries();
      } else {
        this.onLogEntriesSnapshotUnsubscriber = db.neighbourhoodCollection('logEntries')
            .where('route', '==', lastSelectedRoute.key)
            .onSnapshot(this.onLogEntriesSnapshot);
      }
    }

    getAllLogEntries() {
        this.onLogEntriesSnapshotUnsubscriber = db.neighbourhoodCollection('logEntries')
            .orderBy('timestamp', 'desc')
            .onSnapshot(this.onLogEntriesSnapshot);
    }

    componentWillUnmount() {
      this.props.usersContextActions.unregister(this);
      this.onLogEntriesSnapshotUnsubscriber();
    }

    render() {

        return (
            <div className="container logboek  content">

                { this.props.location.state ?
                    (<div>
                        <button type="button" className="btn btn-success" onClick={() => this.props.history.goBack()}>Terug naar Gelopen Rondes overzicht</button>
                        <button type="button" className="btn btn-success" onClick={() => this.getAllLogEntries()}>Toon alle logboekregels</button>
                    </div>) : (<div></div>)
                }

                <Table
                  style={{width: '100%'}}
                  columns={this.state.columns}
                  data={this.state.logEntries}
                  stripe={true}
                  highlightCurrentRow={true}
                  height={600}
                  onCurrentChange={lastSelectedFeedback => this.setState({ lastSelectedFeedback })}
                />

                <Modal
                    className="Modal__Bootstrap modal-dialog modal-lg"
                    closeTimeoutMS={150}
                    isOpen={this.state.infoModalOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel" style={{ fontWeight: '500'}}>Logboekregel details {this.state.selectedLog.uid ? 'van ' + this.props.usersContextActions.uidToDisplayName(this.state.selectedLog.uid) : "Anoniem"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12" id="modal-date">{this.state.selectedLog.timestamp ? 'Datum: ' + moment(this.state.selectedLog.timestamp.toDate()).format('HH:mm ddd D MMM YYYY') : "Onbekend"}</div>
                                <div className="col-lg-6 col-xs-12 text-right" id="modal-status">{'Status: ' + this.state.selectedLog.status}</div>
                                <div className="col-lg-12"><hr /></div>
                                <div className="col-lg-12 col-xs-12" id="modal-description"><h5 style={{ fontWeight: '500'}}>Omschrijving</h5><p>{this.state.selectedLog.description}</p></div>
                                <div className="col-lg-12"><hr /></div>
                                <div className="col-lg-12 col-xs-12" id="modal-description"><h5 style={{ fontWeight: '500'}}>Adres</h5><p>{this.state.selectedLog.address}</p></div>
                                {this.state.selectedLog.requiresFeedback ? 
                                    (<div className="col-lg-12 col-xs-12" id="modal-description" style={{ fontWeight: '500'}}><hr />Teamlid wil graag feedback ontvangen</div>) :
                                    (<div className="col-lg-12 col-xs-12" id="modal-description"><hr />Teamlid wil geen feedback ontvangen</div>)
                                }
                                <div className="col-lg-12"><hr /></div>
                                {this.state.selectedLog.comment != null ? (
                                    <div>
                                        <div className="col-lg-12 col-xs-12" id="modal-comment">
                                            <h5 style={{ fontWeight: '500'}}>Reactie (namens: {this.state.selectedLog.comment != null ? this.state.selectedLog.comment.from : (<span className="text-muted">Wijkcoordinator</span>)})</h5>
                                            <p>{this.state.selectedLog.comment != null ? this.state.selectedLog.comment.content : (<span className="text-muted">Geen reactie</span>)}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-lg-12 col-xs-12" id="modal-comment">
                                        <h5 style={{ fontWeight: '500'}}>Reactie</h5>
                                        <p>{this.state.selectedLog.comment != null ? this.state.selectedLog.comment.content : (<span className="text-muted">Geen reactie</span>)}</p>
                                    </div>
                                )}

                                <div className="col-lg-12"><hr /></div>
                                <div className="col-lg-12 col-xs-12" id="modal-map">{this.state.selectedLog.location ? this.createMap(this.state.selectedLog.location.latitude, this.state.selectedLog.location.longitude) : 'Locatie niet beschikbaar'}</div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}>Sluiten</button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    className="Modal__Bootstrap modal-dialog"
                    closeTimeoutMS={150}
                    isOpen={this.state.commentModalOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reactie plaatsen</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-xs-12" id="modal-description">{this.state.selectedLog.description}</div>
                                <div className="col-lg-12"><hr /></div>
                                <div className="col-lg-12 col-xs-12" id="modal-comment">
                                    <h5>Reactie</h5>
                                    <textarea className="form-control" onChange={(event) => {
                                        this.setState({ newComment: event.target.value });
                                    }} value={this.state.newComment}></textarea>
                                </div>
                                <div className="col-lg-12"><hr /></div>
                                <div className="col-lg-12 col-xs-12">
                                    <h5>Reactie van:</h5>
                                    <select className="form-control" value={this.state.newCommentFrom} onChange={(event) => {
                                        this.setState({ newCommentFrom: event.target.value });
                                    }}>
                                        <option value="Wijkcoordinator">Wijkcoordinator</option>
                                        <option value="Wijkagent">Wijkagent</option>
                                        <option value="Gemeente">Gemeente</option>
                                    </select>
                                </div> 
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}>Annuleren</button>
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.setComment}>Beantwoorden</button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    className="Modal__Bootstrap modal-dialog"
                    closeTimeoutMS={150}
                    isOpen={this.state.modalUpdateLogStatusIsOpen}
                    onRequestClose={this.closeUpdateLogStatusModal}
                    ariaHideApp={false}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Status aanpassen</h4>
                            <button type="button" className="close" onClick={this.closeUpdateLogStatusModal}>
                                <span aria-hidden="true">&times;</span>
                                <span className="sr-only">Annuleren</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span>Weet je zeker dat je de status wilt veranderen naar {this.state.newStatus}?</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeUpdateLogStatusModal}>Annuleren</button>
                            <button type="button" className="btn btn-primary btn-outline" onClick={this.handleUpdateLogStatusModal}>Aanpassen</button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    className="Modal__Bootstrap modal-dialog"
                    closeTimeoutMS={150}
                    isOpen={this.state.modalDeleteLogIsOpen}
                    onRequestClose={this.closeDeleteLogModal}
                    ariaHideApp={false}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Logboekregel verwijderen</h4>
                            <button type="button" className="close" onClick={this.closeDeleteLogModal}>
                                <span aria-hidden="true">&times;</span>
                                <span className="sr-only">Annuleren</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span>Weet je zeker dat je deze logboekregel wilt verwijderen? Het verwijderen van een logboekregel is definitief en niet mogelijk om te herstellen.</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeDeleteLogModal}>Annuleren</button>
                            <button type="button" className="btn btn-danger btn-outline" onClick={this.handleDeleteLogClicked}>Verwijderen</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    archiveLog = (log) => {
        db.neighbourhoodCollection('logEntries').doc(log.key).update({
            isArchived: true
        })
            .then(() => {
                showSnackbar('Logboekregel is gearchiveerd en niet meer zichtbaar in de app');
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
                showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
            });
    }

    unarchiveLog = (log) => {
        db.neighbourhoodCollection('logEntries').doc(log.key).update({
            isArchived: false
        })
            .then(() => {
                showSnackbar('Logboekregel is hersteld en zichtbaar in de app');
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
                showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
            });
    }

    deleteLog = () => {
        const log = this.state.lastSelectedLog
        db.neighbourhoodCollection('logEntries').doc(log.key).delete().then(() => {
            showSnackbar('Logboekregel is verwijderd');
        })
            .catch((error) => {
                console.error("Error removing document: ", error);
                showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
            });
    }

    openDeleteLogModal = (log) => {
        this.setState({ modalDeleteLogIsOpen: true, lastSelectedLog: log});
    }

    closeDeleteLogModal = () => {
        this.setState({ modalDeleteLogIsOpen: false });
    }

    handleDeleteLogClicked = () => {
        this.closeDeleteLogModal();
        this.deleteLog();
    }

    closeModal = () => {
        this.setState({ infoModalOpen: false, commentModalOpen: false });
    }

    renderCommentButton = (object) => {
        return (
            <button className="btn btn-success" title="Reageren" data-toggle="tooltip"
                onClick={() => {
                    this.showModal(object, 'comment');
                }}><i className="material-icons">comment</i>
            </button>
        );
    }

    // Set comment of a specific log
    setComment = () => {
        if (this.state.selectedLog.key != null) {
            db.neighbourhoodCollection('logEntries').doc(this.state.selectedLog.key).update({
                status: "Beantwoord",
                comment: {
                    uid: null,
                    content: this.state.newComment,
                    from: this.state.newCommentFrom,
                    timestamp: new Date()
                }
            });
        }
        this.closeModal();
    }

    // Mark the specified log as marked
    updateStatus = (log, status) => {
        db.neighbourhoodCollection('logEntries').doc(log.key).update({
            status: status
        })
            .then(() => {
                showSnackbar('Status is nu ' + status);
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
                showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
            });
    }

    renderProcessButton = (log) => {
        return (
            <td>
                {log.status !== 'verwerkt' ? (
                    <button className="btn btn-success" title="Verwerken" data-toggle="tooltip"
                        onClick={() => {
                            this.openUpdateLogStatusModal(log, 'verwerkt');
                            // if (window.confirm('Markeren als verwerkt?')) {
                            //     this.updateStatus(log, 'verwerkt');
                            // }
                        }}><i className="material-icons">done</i>
                    </button>
                ) : (
                        <button className="btn btn-warning" title="Heropenen" data-toggle="tooltip"
                            onClick={() => {
                                this.openUpdateLogStatusModal(log, 'nieuw');
                                // if (window.confirm('Heropenen? (wordt gemarkeerd als nieuw)')) {
                                //     this.updateStatus(log, "nieuw");
                                // }
                            }}><i className="material-icons">undo</i>
                        </button>
                    )}

                {this.renderCommentButton(log)}
                <button type="button" className="btn btn-primary" title="Details bekijken"
                    onClick={() => {
                        this.showModal(log, 'info');
                    }} data-toggle="modal" data-target="#logModal">
                    <i className="material-icons">info</i>
                </button>

            <button className="btn btn-danger" title="Verwijderen" data-toggle="tooltip"
                onClick={() => {
                    this.openDeleteLogModal(log);
                }}>
                <i className="material-icons" title="Verwijderen" data-toggle="tooltip">delete_forever</i>
            </button>

            </td>

        );
    }

    showModal = (selectedLogEntry, type) => {
        var newCommentFrom = 'Wijkcoordinator';
        var newComment = '';
        if (selectedLogEntry.comment) {
            newCommentFrom = selectedLogEntry.comment.from !== '' ? selectedLogEntry.comment.from : this.state.newCommentFrom; 
            newComment = selectedLogEntry.comment.content !== '' ? selectedLogEntry.comment.content : this.state.newComment; 
        }
        this.setState({ selectedLog: selectedLogEntry, newCommentFrom, newComment });
        if (type === 'info')
            this.openInfoModal();
        else
            this.openCommentModal();
    }

    openInfoModal = () => {
        this.setState({ infoModalOpen: true });
    }

    openCommentModal = () => {
        this.setState({ commentModalOpen: true });
    }

    // Create the map for eacht logEntries
    createMap = (latPos, longPos) => {
        const MyMapComponent = compose(
            withProps({
                googleMapURL:
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=visualization`,
                loadingElement: <div style={{ height: `100%` }} />,
                containerElement: <div id='map' style={{ height: `250px`, width: `100%` }} />,
                mapElement: <div style={{ height: `100%` }} />
            }),
            withScriptjs,
            withGoogleMap
        )(props => (
            <GoogleMap defaultZoom={15} defaultCenter={{ lat: latPos, lng: longPos }}>
                {props.isMarkerShown && (
                    <Marker position={{ lat: latPos, lng: longPos }} />
                )}
            </GoogleMap>
        ));

        return (<MyMapComponent isMarkerShown />);
    }

    openUpdateLogStatusModal = (lastSelectedLog, newStatus) => {
        this.setState({ modalUpdateLogStatusIsOpen: true, lastSelectedLog, newStatus });
    }
    closeUpdateLogStatusModal = () => {
        this.setState({ modalUpdateLogStatusIsOpen: false, lastSelectedLog: null, newStatus: null });
    }
    handleUpdateLogStatusModal = () => {
        this.updateStatus(this.state.lastSelectedLog, this.state.newStatus);
        this.closeUpdateLogStatusModal();
    }

}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withUsers(Logboek));
