import React from 'react';
import UsersTableRow from './UsersTableRow';
import Modal from 'react-modal';
import Chips, { Chip } from 'react-chips';

export default function UsersTable(props) {
    return (
        <div className="table-wrapper-scroll-y wewatch-scrollbar">
            <table id="table-teamleden" className="table table-striped table-hover" width="100%">
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Tel</th>
                        <th>E-mail</th>
                        <th>Laatst actief</th>
                        <th>Rollen</th>
                        <th>Acties</th>
                    </tr>
                </thead>
                <tbody>
                    {props.users.map((user) =>
                        <UsersTableRow
                            key={user.uid}
                            user={user}
                            currentUser={props.currentUser}
                            getRoleAlias={props.getRoleAlias}
                            functions={props.functions}
                        />
                    )}
                </tbody>
            </table>
            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={props.modalDeleteUserIsOpen}
                onRequestClose={props.functions.closeDeleteUserModal}
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Teamlid verwijderen</h4>
                        <button type="button" className="close" onClick={props.functions.closeDeleteUserModal}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Annuleren</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>Weet je zeker dat je {props.lastSelectedUser.displayName} wilt verwijderen?</span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.functions.closeDeleteUserModal}>Annuleren</button>
                        <button type="button" className="btn btn-danger btn-outline" onClick={props.functions.handleDeleteUserSaveClicked}>Verwijderen</button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={props.modalEditUserIsOpen}
                onRequestClose={props.functions.closeEditUserModal}
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Wachtwoord van gebruiker aanpassen</h4>
                        <button type="button" className="close" onClick={props.functions.closeEditUserModal}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Annuleren</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>Waar wil je de wachtwoord naar veranderen van {props.lastSelectedUser.displayName}?</span><br />
                        <label className="col-form-label">Wachtwoord:</label>
                        <input type="password" className="form-control" id="password" onChange={props.functions.updateInputPincode} pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}" />
                        <div className="alert alert-info  my-md-2">Combinatie van hoofdletter, kleine letter, een cijfer en minstens 8 karakters</div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.functions.closeEditUserModal}>Annuleren</button>
                        <button type="button" className="btn btn-primary btn-outline" onClick={props.functions.handleEditUserSaveClicked}>Aanpassen</button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={props.modalEditUserPhoneNumberIsOpen}
                onRequestClose={props.functions.closeEditUserPhoneNumberModal}
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Telefoonnummer van gebruiker aanpassen</h4>
                        <button type="button" className="close" onClick={props.functions.closeEditUserPhoneNumberModal}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Annuleren</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>Waar wil je het telefoonnummer naar veranderen van {props.lastSelectedUser.displayName}?</span><br />
                        <label className="col-form-label">Telefoonnummer:</label>
                        <input type="text" className="form-control" id="phoneNumber" onChange={props.functions.updateInputPhoneNumber} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.functions.closeEditUserPhoneNumberModal}>Annuleren</button>
                        <button type="button" className="btn btn-primary btn-outline" onClick={props.functions.handleEditUserPhoneNumberSaveClicked}>Aanpassen</button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={props.modalEditUserRolesIsOpen}
                onRequestClose={props.closeEditUserRolesModal}
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Rollen van gebruiker aanpassen</h4>
                        <button type="button" className="close" onClick={props.functions.closeEditUserRolesModal}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Annuleren</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>Waar wil je de rollen naar veranderen van {props.lastSelectedUser.displayName}?</span><br />
                        <label className="col-form-label">Rollen:</label>
                        <Chips
                            value={props.newUserRoles}
                            onChange={props.functions.onChangeRoles}
                            suggestions={props.roles.filter(role => ['user', 'wijkcoordinator', 'wijkagent', 'gemeente'].includes(role.id)).map(role => role.id)}
                            fromSuggestionsOnly={true}
                            uniqueChips={true}
                            alwaysRenderSuggestions={true}
                            renderChip={value => (<Chip>{props.getRoleAlias(value)}</Chip>)}
                            renderSuggestion={suggestion => (<span>{props.getRoleAlias(suggestion)}</span>)}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.functions.closeEditUserRolesModal}>Annuleren</button>
                        <button type="button" className="btn btn-primary btn-outline" onClick={props.functions.handleEditUserRolesSaveClicked}>Aanpassen</button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={props.modalEditUserNotifiersIsOpen}
                onRequestClose={props.functions.closeEditUserNotifiersModal}
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Notificaties van gebruiker aanpassen</h4>
                        <button type="button" className="close" onClick={props.functions.closeEditUserNotifiersModal}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Annuleren</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>Welke notificaties wil je inschakelen voor {props.lastSelectedUser.displayName}?</span>
                        <div className="form-group">
                            <div className="custom-control custom-switch">
                                <input 
                                    type="checkbox" 
                                    className="custom-control-input" 
                                    id="smsNotifications"
                                    checked={props.newUserNotifiers && props.newUserNotifiers.sms !== undefined ? props.newUserNotifiers.sms : false}
                                    onChange={(e) => props.functions.onChangeNotifiers({...props.newUserNotifiers, sms: e.target.checked})}
                                />
                                <label className="custom-control-label" htmlFor="smsNotifications">SMS notificaties</label>
                            </div>
                            <div className="custom-control custom-switch">
                                <input 
                                    type="checkbox" 
                                    className="custom-control-input" 
                                    id="pushNotifications"
                                    checked={props.newUserNotifiers && props.newUserNotifiers.push !== undefined ? props.newUserNotifiers.push : true}
                                    onChange={(e) => props.functions.onChangeNotifiers({...props.newUserNotifiers, push: e.target.checked})}
                                />
                                <label className="custom-control-label" htmlFor="pushNotifications">Push notificaties</label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.functions.closeEditUserNotifiersModal}>Annuleren</button>
                        <button type="button" className="btn btn-primary btn-outline" onClick={props.functions.handleEditUserNotifiersSaveClicked}>Aanpassen</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
